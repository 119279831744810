import React, {useState} from 'react';
import {NavLink, Route, Routes} from 'react-router-dom';
import TrackRecords from './tables/TrackRecords';
import Portfolio from './tables/Portfolio';
import Monitor from './tables/Monitor';
import './App.css';
import Header from "./tables/header/Header";
import USDIStats from './tables/USDIStats';
const HomePage = () => {
    const [lastFetched, setLastFetched] = useState(new Date().toLocaleString());

    return (
        <div>
            <div className="content">
                <Routes>
                    <Route path="track-records" element={<TrackRecords/>}/>
                    <Route path="portfolio" element={<Portfolio/>}/>
                    <Route path="monitor" element={<Monitor/>}/>
                    {/*When you finish the swap page just put in down below*/}
                    
                    <Route path="swap" element={<div style={{ paddingTop: '400px', textAlign: 'center', fontSize: '24px', color: '#555' }}>        
                        Developing...
                    </div>}/>
                    <Route path="usdi-stats" element={<USDIStats/>}/>

                </Routes>
            </div>
        </div>
    );
};

export default HomePage;
