import React, {useEffect, useState} from 'react';
import {digitFormat, moneyFormat, moneyFormat4decimals} from '../utils';
import './USDIStats.css';

const HolderInfoTable = ({parentStatus}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [distributing, setDistribute] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [calculating, setCalculating] = useState(false); // New state for Calculate button
    const [error, setError] = useState(null);
    const [addressToUserIdMap, setAddressToUserIdMap] = useState({});

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await fetch('https://usdi-api-jlukzyz7wa-ew.a.run.app/usdi_holders_info_table');
            if (!response.ok) throw new Error('Network response was not ok');
            const result = await response.json();
            setData(result);

            const rawResponse = await fetch('https://usdi-api-jlukzyz7wa-ew.a.run.app/raw_blockchain_transactions');
            if (!rawResponse.ok) throw new Error('Network response was not ok');
            const rawResult = await rawResponse.json();

            const addressMap = {};
            let userId = 1;
            rawResult.forEach(transaction => {
                const normalizedAddress = transaction.to_address.toLowerCase().slice(0, 42);
                if (!addressMap[normalizedAddress]) {
                    addressMap[normalizedAddress] = userId++;
                }
            });

            setAddressToUserIdMap(addressMap);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleUpdate = async () => {
        try {
            setUpdating(true);
            const response = await fetch('https://distribution-calculator-256611876551.europe-west1.run.app/admin-trigger', {
                method: 'POST',
            });

            if (response.ok) {
                fetchData();
            } else {
                throw new Error('Failed to trigger distribution calculation');
            }
        } catch (error) {
            setError(error);
        } finally {
            setUpdating(false);
        }
    };

    const handleDistribute = async () => {
        try {
            setDistribute(true);
            const response = await fetch('https://distribution-calculator-256611876551.europe-west1.run.app/distribute_usdi', {
                method: 'POST',
            });

            if (response.ok) {
                fetchData();
            } else {
                throw new Error('Failed to trigger distribution');
            }
        } catch (error) {
            setError(error);
        } finally {
            setDistribute(false);
        }
    };

    const handleCalculate = async () => {
        // Prompt the user for input
        let block_number = prompt("Enter Block Number:");
        let final_distribution_amount = prompt("Enter Final Distribution Amount:");

// Process the inputs by removing commas and converting to numbers
        block_number = parseInt(block_number.replace(/,/g, ""), 10); // Convert to integer
        final_distribution_amount = parseFloat(final_distribution_amount.replace(/,/g, "")); // Convert to float

// Check the results
        console.log("Processed Block Number:", block_number);
        console.log("Processed Final Distribution Amount:", final_distribution_amount);


        if (!block_number || !final_distribution_amount) {
            alert("Both fields are required!");
            return;
        }

        try {
            setCalculating(true);
            const response = await fetch('https://distribution-calculator-256611876551.europe-west1.run.app/calculation-trigger', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    block_number: Number(block_number),
                    final_distribution_amount: Number(final_distribution_amount),
                }),
            });

            if (response.ok) {
                fetchData(); // Fetch data after successful calculation
            } else {
                throw new Error('Failed to calculate distribution');
            }
        } catch (error) {
            setError(error);
        } finally {
            setCalculating(false);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const sortedData = data
        .map(row => ({
            ...row,
            userId: addressToUserIdMap[row.address.toLowerCase().slice(0, 42)] || Infinity
        }))
        .sort((a, b) => a.userId - b.userId);

    return (
        <>
            <div className='button_wrapper'>
                <h3>USDI Holders Info</h3>
                <button className={`button ${updating ? 'updating' : ''}`} onClick={handleUpdate} disabled={updating}>
                    {updating ? 'Updating' : 'Update'}
                </button>

                <button className={`button ${calculating ? 'calculating' : ''}`} onClick={handleCalculate}
                        disabled={calculating}>
                    {calculating ? 'Calculating' : 'Calculate'}
                </button>

                <button className={`button ${distributing ? 'distributing' : ''}`} onClick={handleDistribute}
                        disabled={distributing}>
                    {distributing ? 'Distributing' : 'Distribute'}
                </button>
            </div>

            <div className="table-responsive noto">
                <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                    <tr>
                        <th>User Unique ID</th>
                        <th>Address</th>
                        <th>Current Block Number</th>
                        <th>Last Block Number</th>
                        <th>Status</th>
                        <th>BNP</th>
                        <th>Cum DWS</th>
                        <th>Balance of USDI</th>
                        <th>DWS</th>
                        <th>New Cumulative DWS</th>
                        <th>CumDWS Weight %</th>
                        <th>User Undistributed USDI</th>
                        <th>Cumulative Distributed USDI</th>
                        <th>New Cumulative DWS After Distribution</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedData.map((row, index) => (
                        <tr key={index}>
                            <td>{row.userId !== Infinity ? row.userId : 'N/A'}</td>
                            <td>{row.address}</td>
                            <td>{digitFormat(row.current_block_number)}</td>
                            <td>{digitFormat(row.last_block_number)}</td>
                            <td>{row.status}</td>
                            <td>{digitFormat(row.bnp)}</td>
                            <td>{digitFormat(row.cum_dws)}</td>
                            <td>{moneyFormat(row.balance_of_usdi)}</td>
                            <td>{digitFormat(row.dws)}</td>
                            <td>{digitFormat(row.new_cum_dws)}</td>
                            <td>{moneyFormat4decimals(row.cum_dws_weight_percent)}%</td>
                            <td>{moneyFormat4decimals(row.user_undistributed_usdi)}</td>
                            <td>{moneyFormat4decimals(row.cum_distributed_usdi)}</td>
                            <td>0.00</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default HolderInfoTable;
