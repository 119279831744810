// components/USDIStats.js
import React, {useState} from 'react';
import Footer from './footer/Footer';
import Header from './header/Header';
import SmartContractTable from './usdi_tables/SmartContractTable';
import OverallUSDIPayout from './usdi_tables/OverallUsdiPayout';
import RawBlockchain from './usdi_tables/RawBlockchain';
import HolderInfoTable from './usdi_tables/USDIHoldersInfo';
import UserInfo from './usdi_tables/users';
import FilterExample from './usdi_tables/filterExample';
import './usdi_tables/USDIStats.css';

const USDIStats = () => {
     const [parentStatus, setParentStatus] = useState("calculated");

    // Function to handle status change in the parent component
    const handleStatusChangeInParent = (newStatus) => {
        console.log(`Status changed to ${newStatus} in the parent component`);
        setParentStatus(newStatus);
    };
  return (
    <div className="container mt-5">
      <div className="h d-flex justify-content-between align-items-center mb-3">
        <Header />
      </div>
      <div className="title-box d-flex justify-content-between align-items-center">
      </div>
      <h3>Smart Contract Table</h3>
      <SmartContractTable />

      <OverallUSDIPayout onStatusChange={handleStatusChangeInParent} />
      <h3>Raw Blockchain Data</h3>
        {console.log("status",parentStatus)}
      <RawBlockchain /><HolderInfoTable parentStatus={parentStatus} />
      <h3>User Info</h3>
      <UserInfo />
      <h3>Filter by Address Example</h3>
      <FilterExample />
      <Footer />
    </div>
  );
};

export default USDIStats;
